@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "Noto Sans JP", "Hiragino Kaku Gothic ProN", "Meiryo", "sans-serif";
  color: #4c54c0;
  background: repeating-linear-gradient(-45deg, #f3f9f9, #f3f9f9 25%, #eef7f7 25%, #eef7f7 50%);
  background-size: 65px 65px;
  padding: 4px;
  overflow: hidden;
}

p {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
}

@layer utilities {
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .animate-fadeInMenu {
    animation: fadeIn 200ms ease-in-out forwards;
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  .animate-fadeOutMenu {
    animation: fadeOut 200ms ease-in-out forwards;
  }
}

.img-grid {
  @apply grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6;
}

.img-text {
  @apply object-cover cursor-pointer
  w-full h-36 lg:h-72 rounded-md;
}

.input-textarea {
  @apply text-font-color1 bg-slate-50 border-2 border-slate-300
  w-full p-2 rounded-md resize-none overflow-hidden;
}

.input-textarea:focus {
  @apply !outline-none !border-font-color1 bg-white;
}

.link {
  @apply absolute
  invisible
  right-0 w-full h-full
  flex justify-center items-center
  text-font-color1 bg-font-color2
  group-hover:bg-font-color1 group-hover:text-font-color2 group-hover:visible
  z-menulist-10
  border-b-white;
}

.link:hover {
  @apply !text-link-color;
}

.custom-h2 {
  @apply relative py-3 px-6 my-5 ml-2 mr-3 text-2xl font-bold text-font-color1 bg-font-color3 opacity-0 z-h2-0;
  box-shadow: 5px -5px #4c54c0;
}

.custom-h3 {
  @apply text-font-color1 text-lg font-bold bg-font-color3 border-l-4 border-l-font-color1 relative p-3 my-4;
}

.custom-h4 {
  @apply text-font-color1 text-lg font-bold border-b-3 border-b-font-color1 relative p-3;
}

.custom-content-box {
  @apply bg-white 
  box-border border-line-color border-opacity-10 border-solid border-1
  max-w-screen-lg mx-auto
  pb-10 pt-3 px-3
  mt-0 mb-20 ml-2 mr-2;
}

.blogDay {
  @apply flex items-center;
}

.dayIcon {
  @apply w-5 h-auto;
}

.blogyyyymmdd {
  @apply text-day-color pl-1;
}

.custom-link {
  @apply text-link-color underline;
}

.custom-link:hover {
  @apply text-cyan-400 underline;
}

.custom-button {
  @apply bg-font-color2 hover:bg-font-color1 hover:text-font-color2 transition-all duration-300;
}

.custom-2img-mas {
  @apply flex flex-wrap;
}

.custom-2img-sub {
  @apply md:w-1/2 pr-2;
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  @apply z-progressbar-200;
  background: #4c54c0;
  position: fixed;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

.simplebar-scrollbar::before {
  background: linear-gradient(darkblue, skyblue);
}

/* ul,
li {
  color: #4c54c0;
} */

/* ol {
  counter-reset: number; /*数字をリセット*/
/* list-style-type: none !important; /*数字を一旦消す*/
/* padding: 0.3em 0.8em;
  border: solid 2px #4c54c0; */
/* } */
/* ol li {
  border-bottom: dashed 1px #4c54c0;
  position: relative;
  padding: 0.5em 0.5em 0.5em 30px;
  line-height: 1.5em;
} */
/* ol li:before { */
/* 以下数字をつける */
/* position: absolute;
  counter-increment: number;
  content: counter(number); */
/*数字のデザイン変える*/
/* display: inline-block;
  background: #4c54c0;
  color: white;
  font-family: "Avenir", "Arial Black", "Arial", sans-serif;
  font-weight: bold;
  font-size: 14px;
  left: 0;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center; */
/*以下 上下中央寄せのため*/
/* top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); */
/* } */
/* ol li:last-of-type { */
/* border-bottom: none; 最後のliの線だけ消す */
/* } */

/*
h1 {
  text-align: center;
  position: relative;
  padding: 1rem 1.5rem;
  margin: 13px;
  margin-bottom: 60px;
  border: 4px dotted #4c54c0;
  outline: 4px solid #4c54c0;
  outline-offset: 10px;
  color: #4c54c0;
  background-color: #e1e4f5;
} */

/* h1:before {
  position: absolute;
  background-color: transparent;
  top: 4px;
  right: 4px;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  content: "";
  border: 4px dotted #4c54c0;
} */
