.wrap {
  display: flex;
  flex-wrap: wrap;
}

.left,
.right {
  flex-grow: 1;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
}

.left {
  max-height: 600px;
  max-width: 512px;
}
.left img {
  width: 100%;
  height: auto;
}
.right {
  max-width: 100%;
  margin-left: 10px;
}

.wrap.flex-column {
  flex-direction: column;
}

@media (max-width: 600px) {
  .wrap {
    flex-direction: column;
  }
  .left {
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .right {
    margin-left: 0px;
  }
}

#download_button {
  text-decoration: none;
  color: white;
}
